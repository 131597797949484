<template>
  <p-edit />
</template>

<script>
import Edit from './../components/rules/EditRule.vue';

export default {
  components: {
    'p-edit': Edit
  }
};
</script>
